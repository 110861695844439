<template>
  <v-card
    elevation="0"
    class="mt-6 ml-6">
    <!-- header -->
    <div
      style="display: flex"
      class="ml-4">
      <v-btn
        text
        small
        plain
        class="pa-0"
        @click="$router.back()">
        <v-icon
          color="primaryColor"
          class="mr-4"
          >mdi-arrow-left</v-icon
        >
      </v-btn>
    </div>
    <div
      style="display: flex; flex-direction: column"
      class="ml-4">
      <div style="display: flex; align-items: center">
        <v-avatar
          :size="avatarSize"
          class="mr-4 ml-2"
          style="border: 4px solid #ffffff">
          <v-img :src="thumbnailUrl" />
        </v-avatar>
        <!-- author details -->
        <div class="author-details-name">
          {{ author.displayName }}
        </div>
      </div>
      <div
        style="width: 80vw important!"
        class="author-details-container mt-8 ml-4 grey lighten-5 pa-6 rounded-lg">
        <v-tiptap
          view
          style="text-align: justify"
          v-model="author.description">
        </v-tiptap>
      </div>
    </div>

    <v-card-text>
      <!--author courses -->
      <div>
        <div
          class="author-courses-title mt-12 ml-4 mb-8"
          color="offblack">
          More from {{ author.displayName }}
        </div>
        <div class="author-courses-list">
          <v-row no-gutters>
            <v-col
              cols="3"
              class="pa-1"
              v-for="course in courses"
              :key="course.id">
              {{ course.name }}
              <CourseGridCard :course="course" />
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import AuthorDetailsComponentMixin from '@/components/authors/author-details-component/AuthorDetailsComponent.mixin.vue';
  export default {
    mixins: [AuthorDetailsComponentMixin],
  };
</script>

<style lang="css" scoped>
  .author-details-container {
    margin-top: 7vh;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    color: textColor !important;
  }
  .author-details-name {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: var(--v-offBlack-base);
  }
  .author-details-description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: var(--v-offBlack-base);
  }
  .author-courses-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: offBlack;
  }
</style>
